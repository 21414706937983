<template>
  <div>
    <loginDialog ref="dialog"/>
    <div
        :style="$route.name === 'CommoditySearch'|| $route.name === 'homepage' || $route.name === 'ProductDetails' || $route.name === 'goodsMonthlyFocus'? '' : 'height:56px'"
        class="headMain">
      <div id="HomePageTop">
        <div v-show="showStatus === true" id="qitasss" style="background: #B4272B;height: 56px">
          <div class="qiTa">
            <div class="fontSize14" style="color: #fff">1688・タオバオ・Tmallから楽々と輸入事業へ</div>
            <div class="zhiNan">
              <span @click="pageJump('/guia-de-usuario')">
                {{ $fanyi("用户指南") }}
              </span>
              <span @click="pageJump('/internationalLogistics')">
                {{ $fanyi("国際送料詳細") }}
              </span>
              <span @click="pageJump('/comisiones-rakumart')">
                {{ $fanyi("手数料について") }}
              </span>
              <span @click="pageJump('/option')">
                {{ $fanyi("附加服务说明") }}
              </span>
              <span @click="pageJump('/tollVipApplyFor')">
                {{ $fanyi("收费会员服务") }}
              </span>
              <span @click="blogPageJump">
                BLOG
              </span>
              <span @click="pageJump('/contactUs')">
                {{ $fanyi("お問い合わせ") }}
              </span>
            </div>
            <div class="huiLv">
              <span class="numd" style="font-weight: 400">1{{ $fanyi("元") }} </span> =
              <font class="num">{{ $store.state.exchangeRate }}{{ $fanyi("円") }}</font>
              <el-popover placement="top" trigger="hover">
                <div style="width: 320px">
                  <div>三菱UFJ銀行 TTS レート+0.7<br>
                    日本時間午前9:30~11:00更新
                  </div>
                </div>
                <img slot="reference" alt="" src="../../assets/4.png" style="margin-top: 3px">
              </el-popover>
            </div>
          </div>
        </div>
        <div class="fgx"></div>
        <div
            v-if="$route.name === 'CommoditySearch' || $route.name === 'homepage'|| $route.name === 'ProductDetails'||$route.name === 'goodsMonthlyFocus'"
            id="HomePageTopCon">
          <div class="logoBox">
            <img id="logo" alt="" src="../../assets/1688/commoditySearchPageLogo.png" @click="backHome"/>
          </div>
          <div class="searchBoxDiv">
            <el-select
                v-model="$store.state.shopType"
                placeholder=""
                @change="valueChange"
            >
              <el-option
                  v-for="(item,index) in options"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
            <!-- 请输入您想搜索的产品关键字 -->
            <el-popover
                ref="popover"
                placement="bottom"
                :visible-arrow="false"
                :offset="4"
                trigger="click"
                :popper-class="popoverClass"
                width="668">
              <div class="keywordRecommendationContainer">
                <div v-if="keyWordList.length>0" class="keywordRecommendationHeader">
                  <div class="keywordRecommendationHeaderTitle">{{ $fanyi('历史记录') }}</div>
                  <div class="oldKeyWordContainer">
                    <div v-for="(item,index) in keyWordList" :key="index" class="oldKeyWordItem"
                         @click="$store.commit('getsearchInfo', item);$fun.routerToPage('/CommoditySearch?keyword='+item);">
                      <div>{{ item }}</div>
                      <i class="el-icon-close" @click.stop="deleteOldKeyWord(index)"></i>
                    </div>
                  </div>
                </div>
                <div class="keywordRecommendationFooter">
                  <div v-for="(item,index) in topKeywordList" :key="index">
                    <div class="topKeywordType">{{ item.translate_name }}</div>
                    <div class="relationHotCateListContainer">
                      <div v-for="(newItem,newIndex) in item.relation_hot_cate" :key="newIndex"
                           @click="add(newItem)">
                        {{ newItem.seKeywordTranslation }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <el-input id="keywordInput"
                        slot="reference"
                        v-model="$store.state.searchInfo"
                        :placeholder="$fanyi('请输入您想查找的商品名称或者淘宝与1688的商品URL')"
                        @keydown.enter.native="search"
                        style="width: 400px"
                        @paste.native.capture.prevent="myFunction($event)"/>
            </el-popover>
            <el-upload v-show="$store.state.userInfo != null && token === true&&imgPreviewUrlDialogVisible==false"
                       :action="uploadUrl"
                       :before-upload="befoUpload" :http-request="httpup"
                       :show-file-list="false"
                       accept=".jpg,.jpeg,.png" class="upImg" list-type="picture-card">
              <el-popover
                  :popper-class="popoverClass"
                  placement="bottom"
                  trigger="hover"
                  width="290">
                <div>{{ $fanyi("ctrl+v粘贴图片，可快速传图到搜索") }}</div>
                <img slot="reference" src="../../assets/hometop/camara.svg"/>
              </el-popover>
            </el-upload>
            <el-popover
                v-show="$store.state.userInfo != null && token === true&&imgPreviewUrlDialogVisible==true"
                ref="imgPreviewPopover"
                v-model="imgPreviewUrlDialogVisible"
                :popper-class="imgPreviewPopoverClass"
                placement="bottom"
                trigger="manual"
                width="220">
              <div>
                <div class="cloneContainer" @click.stop="imgPreviewUrlDialogVisible=false;">
                  <div>X</div>
                </div>
                <img :src="imgPreviewUrl" alt="" class="imgPreview">
                <div class="submitImgUploadBtn" @click.stop="submitImgUpload">
                  {{ $fanyi("搜索图片") }}
                </div>
              </div>
              <div slot="reference" class="el-upload">
                <img class="cursorPointer" src="../../assets/hometop/camara.svg"/>
              </div>
            </el-popover>
            <el-popover
                v-show="$store.state.userInfo==null"
                ref="noLoginPopover"
                :popper-class="popoverClass"
                placement="bottom"
                trigger="hover"
                width="290">
              <div>{{ $fanyi("ctrl+v粘贴图片，可快速传图到搜索") }}</div>
              <img slot="reference" alt="" class="camara cursorPointer"
                   src="../../assets/hometop/camara.svg"
                   @click="$refs.dialog.visible = true"/>
            </el-popover>
            <el-button @click="search" data-logger-action-type="fxg_7746786.rakumart">
              <img src="../../assets/newImg/smallss.svg"/>
            </el-button>
          </div>
          <div class="operate">
            <div v-if="!token" class="userDetail notLogin">
              <span>
                <span>
                  <span class="linkSpan" @click="$fun.routerToPage('/register')">
                    <!--  -->{{ $fanyi("注册") }}</span>
                </span>
                <span class="fg"> / </span>
                <span>
                  <span class="linkSpan" @click="$fun.routerToPage('/login')">
                    <!-- 登录 -->{{ $fanyi("登录") }}</span>
                </span>
              </span>
            </div>
            <div v-else class="userDetail">
              <img v-if="userInfo.portrait_url !== ''" :src="userInfo.portrait_url" class="userHeadImg"
                   style="cursor: pointer" @click="$fun.routerToPage('/user/index')"/>
              <img v-else class="userHeadImg" src="../../assets/base-user.png" style="cursor: pointer"
                   @click="$fun.routerToPage('/user/index')"/>
              <div class="userLoginInfoBox">
                <p :title="userInfo.realname" class="toUserIndex u-line">{{ userInfo.realname }} 様</p>
                <p>
                  <span class="routerBtn" @click="loginOut">{{ $fanyi("退出登录") }}</span>
                  <span style="font-size: 12px"> / </span>
                  <span class="routerBtn" @click="$fun.routerToPage('/user/index')">
                    {{ $fanyi("个人中心") }}
                  </span>
                </p>
              </div>
              <div v-if="false" class="dropDownBox">
                <span @click="loginOut">{{ $fanyi("退出登录") }} </span>
                <span @click="$fun.routerToPage('/user/index');">
                  {{ $fanyi("个人中心") }}
                </span>
                <!-- <span> {{ $fanyi("意见反馈") }} </span> -->
              </div>
            </div>
            <div ref="end" class="cartBtn">
              <button type="button" @click="cartPageJump">
                <img id="gouwuche" src="../../assets/newImg/gouwuche.svg"/>
                {{ $fanyi("购物车") }}
                <div v-if="token === true" class="cartCount">{{ $store.state.cartCount }}</div>
              </button>
            </div>
          </div>
        </div>
        <div v-else class="newHomePageTopCon">
          <div class="Container">
            <img id="logo" alt="" class="cursorPointer" src="../../assets/1688/commoditySearchPageLogo.png"
                 @click="backHome"/>
            <div class="zhiNan">
              <!--            <span @click="$fun.routerToPage('/quienes-somos', true)">-->
              <!--              {{ $fanyi("关于RAKUMART") }}-->
              <!--            </span>-->
              <span @click="pageJump('/guia-de-usuario')">
                {{ $fanyi("用户指南") }}
              </span>
              <span @click="pageJump('/internationalLogistics')">
                {{ $fanyi("国際送料詳細") }}
              </span>
              <span @click="pageJump('/comisiones-rakumart')">
                {{ $fanyi("手数料について") }}
              </span>
              <span @click="pageJump('/option')">
                {{ $fanyi("附加服务说明") }}
              </span>
              <span @click="pageJump('/tollVipApplyFor')">
                {{ $fanyi("收费会员服务") }}
              </span>
              <span @click="blogPageJump">
                BLOG
              </span>
              <span @click="pageJump('/contactUs')">
                {{ $fanyi("お問い合わせ") }}
              </span>
            </div>
            <div v-if="!token" class="userDetail notLogin">
              <span>
                <span>
                  <span class="linkSpan" @click="$fun.routerToPage('/register')">
                    <!--  -->{{ $fanyi("注册") }}</span>
                </span>
                <span class="fg"> / </span>
                <span>
                  <span class="linkSpan" @click="$fun.routerToPage('/login')">
                    <!-- 登录 -->{{ $fanyi("登录") }}</span>
                </span>
              </span>
            </div>
            <div v-else class="userDetail notLogin">
              <span class="loginOut" @click="loginOut">{{ $fanyi("退出登录") }} </span>
              <span class="fg" style="display: inline-block;margin: 0 2px"> / </span>
              <p :title="userInfo.realname" class="toUserIndex u-line" @click="$fun.routerToPage('/user/index');">
                {{ userInfo.realname }}</p>
            </div>
            <div class="huiLv">
              <span class="numd" style="font-weight: 400">1{{ $fanyi("元") }} </span> =
              <font class="num">{{ $store.state.exchangeRate }}{{ $fanyi("円") }}</font>
              <el-popover placement="top" trigger="hover">
                <div style="width: 320px">
                  <div>三菱UFJ銀行 TTS レート+0.7<br>
                    日本時間午前9:30~11:00更新
                  </div>
                </div>
                <div slot="reference" class="questionMark">?</div>
              </el-popover>
            </div>
          </div>
        </div>
        <div class="hideOperationContainer" @click="isShow = !isShow">
          {{ isShow === true ? `${$fanyi(`隐藏`)} >` : `< ${$fanyi(`展开`)}` }}
        </div>
        <transition>
          <div v-show="isShow === true" class="rightMenu">
            <div :class="personalCenterOne === false ? 'personalCenterOne' : 'newPersonalCenterOne'"
                 @click="goPage('index');addActionBarCount('个人中心')" @mouseenter="personalCenterOneMouseenter"
                 @mouseleave="personalCenterOneMouseleave">
              <div class="container">
                <img v-show="personalCenterOne === false" src="../../assets/homePage/index/personalCenterGrey.png">
                <div v-show="personalCenterOne === true" style="color:#fff">{{ $fanyi('个人中心') }}</div>
                <img v-show="personalCenterOne === true" src="../../assets/homePage/index/personalCenterWhite.png">
              </div>
            </div>
            <div :class="cart === false ? 'cart' : 'newCart'" @click="goPage('cart');addActionBarCount('购物车')"
                 @mouseenter="cartMouseenter"
                 @mouseleave="cartMouseleave">
              <div class="container">
                <img v-show="cart === false" src="../../assets/homePage/index/cartGrey.png">
                <div v-show="cart === true" style="color:  #fff">{{ $fanyi('购物车') }}</div>
                <img v-show="cart === true" src="../../assets/homePage/index/cartWhite.png">
                <div v-if="token === true&&$store.state.cartCount>0"
                     :style="cart === false ? 'background-color:#B4272B;color:#fff' : ''"
                     class="cartCountContainer flexAndCenter" style="margin-top: -12px">{{ $store.state.cartCount }}
                </div>
              </div>
            </div>
            <div :class="issueOrderStatus === false ? 'issueOrder' : 'newIssueOrder'"
                 @click="$refs.commercialMatchRef.commercialMatchDialogVisible=true;addActionBarCount('比较')"
                 @mouseenter="issueOrderMouseenter" @mouseleave="issueOrderMouseleave">
              <div class="container">
                <img v-show="issueOrderStatus === false" src="../../assets/1688/goodsComparisonIcon.svg">
                <div v-show="issueOrderStatus === true" style="color:  #fff">{{ $fanyi('比較') }}</div>
                <img v-show="issueOrderStatus === true&&$store.state.commercialMatchGoodsCount>0"
                     src="../../assets/1688/goodsComparisonActiveIcon.svg"
                     style="width: 21px;height: 24px;">
                <div class="cartCountContainer flexAndCenter"
                     style="background-color:#B4272B;color:#fff;margin-top: -12px">{{
                    $store.state.commercialMatchGoodsCount
                  }}
                </div>
              </div>
            </div>
            <div :class="warehouseStatus === false ? 'warehouse' : 'newWarehouse'"
                 @click="goPage('inform');addActionBarCount('通知')"
                 @mouseenter="warehouseMouseenter" @mouseleave="warehouseMouseleave">
              <div class="container">
                <img v-show="warehouseStatus === false" src="../../assets/informNoHover.svg">
                <div v-show="warehouseStatus === true" style="color:  #fff">{{ $fanyi('通知') }}</div>
                <img v-show="warehouseStatus === true" src="../../assets/informHover.svg"
                     style="width: 21px;height: 24px;">
                <div v-if="token === true&&informCount>0"
                     :style="warehouseStatus === false ? 'background-color:#B4272B;color:#fff' : ''"
                     class="cartCountContainer flexAndCenter" style="margin-top: -12px">{{ informCount }}
                </div>
              </div>
            </div>
            <div :class="orderStatus === false ? 'order' : 'newOrder'"
                 @click="goPage('order');addActionBarCount('订单')"
                 @mouseenter="orderMouseenter" @mouseleave="orderMouseleave">
              <div class="container">
                <img v-show="orderStatus === false" src="../../assets/homePage/index/orderGrey.png">
                <div v-show="orderStatus === true" style="color:#fff">{{ $fanyi('全部订单') }}</div>
                <img v-show="orderStatus === true" src="../../assets/homePage/index/orderWhite.png">
              </div>
            </div>
            <div :class="chat === false ? 'chat' : 'newChat'" @click="goPage('chat');addActionBarCount('聊天')"
                 @mouseenter="chatMouseenter"
                 @mouseleave="chatMouseleave">
              <!--              v-if="userInfo==null||(userInfo!=null&&userInfo.chatwork=='')"-->
              <div class="container">
                <transition v-if="messageUnreadNum > 0 && chat === false" name="el-fade-in-linear">
                  <div v-show="newIsShow" style="height: 24px">
                    <img class="chatGrey" src="../../assets/homePage/index/rakuchatGrey.svg">
                    <div :style="chat === false ? 'background-color:#B4272B;color:#fff' : ''"
                         class="cartCountContainer flexAndCenter" style="top:8px">
                      {{ messageUnreadNum }}
                    </div>
                  </div>
                </transition>
                <img v-if="chat === false && messageUnreadNum === 0" class="chatGrey"
                     src="../../assets/homePage/index/rakuchatGrey.svg">
                <div v-show="chat === true" style="color:  #fff">{{ $fanyi('チャット') }}</div>
                <img v-show="chat === true" src="../../assets/homePage/index/rakuchatWhite.svg">
              </div>
              <!--              <div  class="container">-->
              <!--                <img v-if="chat === false" class="chatGrey"-->
              <!--                     src="../../assets/homePage/index/chatworkGrey.svg">-->
              <!--                <div v-show="chat === true" style="color:  #fff">chatwork</div>-->
              <!--                <img v-show="chat === true" src="../../assets/homePage/index/chatworkWhite.svg">-->
              <!--              </div>-->
            </div>
          </div>
        </transition>
        <div v-show="isShow === true && this.scrollTop >= 400" class="goTopContainer flexAndCenter" @click="toTop">
          <img alt="" src="../../assets/homePage/index/goTop.png">
        </div>
      </div>
    </div>
    <sideBar/>
    <feedback/>
    <commercialMatch ref="commercialMatchRef"/>
    <div v-if="isPluginInstalled" class="installPrompt">
      <div class="installPromptBox">
        <div class="conBox">
          <img :src="require('@/assets/hometop/rakumartandGoogle.svg')" alt="" class="RAGlogoImg"/>
          <span>お知らせ：ラクマート拡張ツールを使うことでリサーチがさらに簡単になります。</span>
          <a class="toGooGleShopBtn"
             href="https://chrome.google.com/webstore/detail/rakumart%E4%BB%A3%E8%A1%8C%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88new/kiigkmeopkjhbpoijmdkolcpiaibfjjg?utm_source=ext_sidebar&hl=ja"
             target="_blank" @mouseenter="aMouseenter" @mouseleave="aMouseleave">
            <img :src="imgUrl" alt="">
            インストールへ
          </a>
          <div class="closeBtn" @click="closePromptClick">
            <img :src="require('@/assets/hometop/closeWhite.png')" alt=""/>
          </div>
        </div>
      </div>
    </div>
    <div v-if="yearCalendarDialogVisible" class="dialogVisibleContainer">
      <yearCalendar :data="yearCalendarDialogVisible" :list="calendarList" :nowadayYear="year"
                    @close="yearCalendarDialogVisible = false"/>
    </div>
  </div>
</template>


<script>
import loginDialog from '@/components/public/loginDialog'
import feedback from "@/components/head/feedback";
import sideBar from "../homePage/sideBar.vue";
import yearCalendar from "@/components/head/components/yearCalendar.vue";
import commercialMatch from "@/views/order/components/commercialMatch.vue";
import {getNextMonth, getPreMonth} from '@/utlis/date'

export default {
  data() {
    return {
      isShow: true,
      scrollTop: 0,
      value: '1688',
      translateTableData: [],
      imgUrl: require('../../assets/hometop/blackIcon.png'),
      newIsShow: true,
      languageStatus: false,
      personalCenterOne: false,
      orderStatus: false,
      personalCenterTwo: false,
      issueOrderStatus: false,
      cart: false,
      chat: false,
      calendarList: [],
      imgPreviewUrl: {},
      imgPreviewUrlDialogVisible: false,
      options: [{
        label: '1688',
        value: '1688',
      },
        {
          label: '1688日本市場人気商品',
          value: '1688日本市場人気商品',
        }, {
          label: '1688韓国市場人気商品',
          value: '1688韓国市場人気商品',
        },
        {
          label: 'タオバオ&Tmall',
          value: 'taobao',
        }
      ],
      theSameMonthValue: '',
      nextMonthValue: '',
      year: null,
      base64: '',
      calendarStatus: false,
      messageUnreadNum: 0,
      yearCalendarDialogVisible: false,
      goodsMessageUnread: null,
      warehouseStatus: false,
      porderStatus: false,
      pageLoading: null,
      showStatus: false,
      closePrompt: !!localStorage.getItem("closePrompt"),
      topKeywordList: [],
      popoverClass: 'indexSearchInfo',
      imgPreviewPopoverClass: 'imgPreviewPopoverClass',
      keyWordList: [],
      commercialMatchList: [],
      files: {},
      informCount: 0,
    };
  },
  created() {
    // 防止搜索栏分类在跳转页面时因重新加载而清空
    this.$store.state.searchInfoue = this.$store.state.goodsListActiveId;
    this.$store.dispatch("goodsToCartNum");
  },
  watch: {
    $route: {
      handler(route) {
        this.getTimMessageUnreadNum();
      },
    },
  },
  components: {
    feedback,
    sideBar,
    yearCalendar,
    loginDialog,
    commercialMatch
  },
  computed: {
    searchInfo() {
      return this.$store.state.searchInfo;
    },
    isPluginInstalled() {
      return (!document.getElementById("jp-rakumart-plugin-dom") && !this.closePrompt);
    },
    goodsNum() {
      return this.$store.state.cartCount;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    exchangeRate() {
      let exchangeRate = localStorage.getItem('exchangeRate');
      return Number(exchangeRate)
    },
    token() {
      return !!this.$store.state.userInfo;
    },
  },
  mounted() {
    if (this.$route.name === 'CommoditySearch' || this.$route.name === 'homepage' || this.$route.name === 'ProductDetails' || this.$route.name === 'goodsMonthlyFocus') {
      this.showStatus = true;
    }
    this.changeHeight();
    this.theSameMonthValue = this.$fun.getNextMoth().a_Date;
    this.nextMonthValue = this.$fun.getNextMoth().b_Date;
    this.$api.topKeyword().then((res) => {
      this.topKeywordList = res.data;
    })
    if (localStorage.getItem('keyWord') != null) {
      this.keyWordList = JSON.parse(localStorage.getItem('keyWord'));
    }
    this.$api.noticeUnreadNotice().then((res) => {
      if (res.code != 0) return;
      this.informCount = res.data.length;
    });
    this.getCommercialMatchList();
    // // 监听页面刷新事件
    // window.addEventListener('beforeunload', this.handleBeforeUnload);
  },
  beforeDestroy() {
    // 组件销毁前移除事件监听
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
  methods: {
    //第一个个人中心鼠标移入
    personalCenterOneMouseenter() {
      this.personalCenterOne = true;
    },
    //获取比较列表
    getCommercialMatchList() {
      if (localStorage.getItem('commercialMatchList') != null) {
        this.commercialMatchList = JSON.parse(localStorage.getItem('commercialMatchList'));
        this.$store.commit('setCommercialMatchGoodsCount', this.commercialMatchList.length)
      }
    },
    aMouseleave() {
      this.imgUrl = require('../../assets/hometop/blackIcon.png');
    },
    aMouseenter() {
      this.imgUrl = require('../../assets/hometop/redIcon.png');
    },
    getLastMonth() {
      this.theSameMonthValue = getPreMonth(this.theSameMonthValue);
      this.nextMonthValue = getNextMonth(this.theSameMonthValue);
      if (this.theSameMonthValue.indexOf('01-001') != -1) {
        let year = this.theSameMonthValue.substr(0, this.theSameMonthValue.length - 7);
        this.$api.getCalendar({year: Number(year) - 1}).then((res) => {
          res.data.forEach((item) => {
            this.calendarList.push(item)
          })
        })
      }
    },
    getNewNextMonth() {
      this.nextMonthValue = getNextMonth(this.nextMonthValue);
      this.theSameMonthValue = getPreMonth(this.nextMonthValue);
      if (this.nextMonthValue.indexOf('12-001') != -1 || this.nextMonthValue.indexOf('01-001') != -1) {
        let year = this.theSameMonthValue.substr(0, this.theSameMonthValue.length - 7);
        this.$api.getCalendar({year: Number(year) + 1}).then((res) => {
          res.data.forEach((item) => {
            this.calendarList.push(item)
          })
        })
      }
    },
    add(item) {
      if (this.$fun.isOfficialWeb()) {
        __bl.sum('关键词推荐点击数');
      }
      this.$store.commit("getsearchInfo", item.seKeywordTranslation);
      this.$fun.routerToPage('/CommoditySearch?keyword=' + item.seKeyword);
    },
    //操作栏数据埋点
    addActionBarCount(val) {
      let user_token = localStorage.getItem('japan_user_token')
      if (this.$fun.isOfficialWeb() && user_token) {
        __bl.sum(`从操作栏访问${val}点击数`);
      }
    },
    // 图片上传事件
    async httpup(file) {
      const imgOBJ = await this.$fun.startUpload('multipartUpload', file.file, this.MastermaphandleUploadProgress)
      if (imgOBJ.res.requestUrls[0] && imgOBJ.res.status == 200) {
        this.uploadPercentage = 0
        this.successup(imgOBJ.res.requestUrls[0])
      }
    },
    MastermaphandleUploadProgress(rawFile, num) {
      // 使用 Number.isFinite 来检查 num 是否是一个有限数字
      if (Number.isFinite(num)) {
        this.uploadPercentage = num;
      } else {
        this.uploadPercentage = 50
      }
    },
    valueChange(e) {
      if (this.$route.name === 'CommoditySearch') {
        this.$emit('shopTypeChange', e)
      }
      this.$store.commit("setShopType", e);
    },
    // 关闭推广
    closePromptClick() {
      this.closePrompt = true;
      localStorage.setItem("closePrompt", true);
    },
    // 从事件列表里查找出对应日期的时间数据,输入2023-01-16,输出{id: 1842, date: "2023-01-16", week: 1, is_holiday: 1, color: "", remark: null}
    dataMatching(v) {
      let data = this.calendarList.find((item) => {
        return item.date == v;
      });
      return data;
    },
    blogPageJump() {
      let user_token = localStorage.getItem('japan_user_token');
      if (user_token != null) {
        let userData = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("userData"))));
        window.open(`https://blog.rakumart.com/?marker=rakumart&userId=${userData.operation_id}`, "_blank")
      } else {
        window.open('https://blog.rakumart.com/?marker=rakumart', "_blank")
      }
    },
    changeHeight() {
      let that = this;
      window.onscroll = function () {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        this.scrollTop = scrollTop;
        if (scrollTop >= 190) {
          if (that.showStatus === true) {
            document.getElementsByClassName("headMain")[0].style.height = "90px";
          }
          document.getElementById("qitasss").style.height = "0";
          if (document.getElementById("mask")) {
            document.getElementById("mask").style.top = "150px";
          }
          that.popoverClass = 'newIndexSearchInfo';
          that.imgPreviewPopoverClass = 'newImgPreviewPopoverClass';
        } else if (scrollTop <= 100) {
          if (that.showStatus === true) {
            document.getElementById("HomePageTopCon").style.height = "85px";
            document.getElementById("qitasss").style.height = "56px";
            document.getElementsByClassName("headMain")[0].style.height = "143px";
          }
          if (document.getElementById("mask")) {
            document.getElementById("mask").style.top = "220px";
          }
          that.popoverClass = 'indexSearchInfo';
          that.imgPreviewPopoverClass = 'imgPreviewPopoverClass';
        }
        // 是否显示top键 (ps:topBtn不在这个页面里，在sideBar.vue里)
        if (scrollTop >= 400) {
          document.getElementsByClassName("goTopContainer")[0].style.display = "flex";
        } else if (scrollTop <= 430) {
          document.getElementsByClassName("goTopContainer")[0].style.display = "none";
        }
      };
      this.getTimMessageUnreadNum();
      let date = new Date();
      let year = date.getFullYear();
      this.year = year;
      this.$forceUpdate();
      let month = date.getMonth() + 1;
      this.$api.getCalendar({year: year}).then((res) => {
        this.calendarList = res.data;
      })
      if (month === 12) {
        this.$api.getCalendar({year: year + 1}).then((res) => {
          res.data.forEach((item) => {
            this.calendarList.push(item)
          })
        })
      }
    },
    getTimMessageUnreadNum() {
      let user_token = localStorage.getItem('japan_user_token')
      if (user_token) {
        this.$api.getTimMessageUnreadNum().then(res => {
          if (res.code == 0) {
            this.messageUnreadNum = res.data.messageUnreadNum;
          } else {
            this.messageUnreadNum = 0;
          }
          if (res.data.messageUnreadNum > 0) {
            this.goodsMessageUnread = setInterval(() => {
              this.newIsShow = !this.newIsShow;
            }, 900)
          }
        })
      }
      this.$api.commonExchangeRate().then((res) => {
        this.$store.commit("getExchangeRate", res.data.rmb_to_jpy);
        this.$forceUpdate();
      });
    },
    // 返回首页
    backHome() {
      // 假如当前在首页就重新刷新页面
      if (this.$route.name == "homepage") {
        return this.$router.go(0);
      }
      this.$fun.routerToPage("/");
      this.$store.commit("getsearchInfo", "");
      this.$store.commit("setShopType", '1688');
    },
    // 跳转搜索页
    search() {
      if (this.$store.state.searchInfo == '') {
        this.$message(this.$fanyi("请输入关键词搜索"));
        return;
      }
      let regtaobao = RegExp(/taobao/);
      let reg1688 = RegExp(/1688/);
      let regtmall = RegExp(/tmall/);
      let regrakumart = RegExp(/goods_id/);
      let oldrakumart = RegExp(/rakumart.com/);
      let type = "";
      let id = '';
      var reg1 = /1688.com\/offer\/([0-9]+)/;
      var reg4 = /m\.1688\.com.*offerId=([0-9]{10,})/; //1688手机
      var reg2 = /[?|&]id=(\d{10,15})/;
      var reg5 = /[?|&]goods_id=(\d{10,15})/;
      // 判断是哪个网站的商品链接
      if (regrakumart.test(this.$store.state.searchInfo)) {
        id = this.$store.state.searchInfo.match(reg5)[1];
        let obj = this.getQueryString();
        if (obj.type != undefined || obj.fromPlatform != undefined) {
          if (obj.type != undefined) {
            this.$fun.newToCommodityDetails(id, obj.type, false, 'link');
          } else {
            this.$fun.newToCommodityDetails(id, obj.fromPlatform, false, 'link');
          }
        } else {
          this.$fun.newToCommodityDetails(id, '1688', false, 'link');
        }
        return false
      } else if (oldrakumart.test(this.$store.state.searchInfo)) {
        id = this.$store.state.searchInfo.match(reg2)[1];
        let obj = this.getQueryString();
        if (obj.act != undefined) {
          this.$fun.newToCommodityDetails(id, obj.act, false, 'link');
        } else {
          this.$fun.newToCommodityDetails(id, '1688', false, 'link');
        }
        return false
      } else {
        if (regtaobao.test(this.$store.state.searchInfo) && this.$store.state.searchInfo.match(reg2) != null) {
          id = this.$store.state.searchInfo.match(reg2)[1];
          this.$fun.newToCommodityDetails(id, 'taobao', false, 'link');
          return false
        } else if (reg1688.test(this.$store.state.searchInfo)) {
          type = "1688";
          if (this.$store.state.searchInfo.match(reg1) == null) {
            if (this.$store.state.searchInfo.match(reg4) != null) {
              id = this.$store.state.searchInfo.match(reg4)[1];
            }
          } else {
            id = this.$store.state.searchInfo.match(reg1)[1];
          }
          if (id != '') {
            this.$fun.newToCommodityDetails(id, '1688', false, 'link')
          } else {
            this.$fun.routerToPage("/CommoditySearch?keyword=" + this.$store.state.searchInfo);
          }
          return false
        } else if (regtmall.test(this.$store.state.searchInfo)) {
          type = "tmall";
          id = this.$store.state.searchInfo.match(reg2)[1];
          this.$fun.newToCommodityDetails(id, 'tmall', false, 'link');
          return false
        }
      }
      // 如果是商品搜索页面并且路由上的id和选择器上的id不一致就重设路由
      if (this.$route.name == "CommoditySearch") {
        this.$fun.routerToPage(`/CommoditySearch?keyword=${this.$store.state.searchInfo}`)
        if (this.$fun.isOfficialWeb()) {
          __bl.sum('关键词搜索点击数');
        }
        return
      }
      // 如果搜索的是同一词条就刷新页面
      if (this.$store.state.searchInfo == this.$route.query.keyword) {
        this.$router.go(0);
        return;
      }
      // 如果关键词为空就不跳转
      if (!this.$store.state.searchInfo) {
        this.$message(this.$fanyi("请输入关键词搜索"));
        return;
      }
      if (this.$fun.isOfficialWeb()) {
        __bl.sum('关键词搜索点击数');
      }
      // 跳转
      this.$fun.routerToPage("/CommoditySearch?keyword=" + this.$store.state.searchInfo);
    },
    //获取路径参数
    getQueryString() {
      var qs = location.search.substr(1), // 获取url中"?"符后的字串
          args = {}, // 保存参数数据的对象
          items = qs.length ? qs.split("&") : [], // 取得每一个参数项,
          item = null,
          len = items.length;

      for (var i = 0; i < len; i++) {
        item = items[i].split("=");
        var name = decodeURIComponent(item[0]),
            value = decodeURIComponent(item[1]);
        if (name) {
          args[name] = value;
        }
      }
      return args;
    },
    //
    pageJump(val) {
      window.open(val, "_blank");
    },
    deleteOldKeyWord(i) {
      this.keyWordList.splice(i, 1);
      localStorage.setItem("keyWord", JSON.stringify(this.keyWordList))
      this.keyWordList = JSON.parse(localStorage.getItem('keyWord'))
    },
    myFunction(event) {
      if (JSON.stringify(event.clipboardData.files) !== '{}') {
        const isJPG = ["image/jpeg", "image/png", "image/jpg"].indexOf(event.clipboardData.files[0].type) != -1;
        if (!isJPG) {
          this.$message.error(this.$fanyi("请上传图片"));
          return isJPG;
        }
        this.imgPreviewUrl = window.URL.createObjectURL(event.clipboardData.files[0]);
        this.base64 = this.$fun.convertImageToBase64(event.clipboardData.files[0])
        this.files = event.clipboardData.files;
        this.imgPreviewUrlDialogVisible = true;
      } else {
        var elInput = document.getElementById('keywordInput') // 根据id选择器选中对象
        var startPos = elInput.selectionStart// input 第0个字符到选中的字符
        var endPos = elInput.selectionEnd// 选中的字符到最后的字符
        if (startPos === undefined || endPos === undefined) {
          this.$store.state.searchInfo = this.$store.state.searchInfo + event.clipboardData.getData('Text');
        } else {
          var txt = elInput.value
          // 将表情添加到选中的光标位置
          var result = txt.substring(0, startPos) + event.clipboardData.getData('Text') + txt.substring(endPos)
          elInput.value = result// 赋值给input的value
          // 重新定义光标位置
          elInput.focus()
          elInput.selectionStart = startPos + event.clipboardData.getData('Text').length + 3
          elInput.selectionEnd = startPos + event.clipboardData.getData('Text').length + 3
          this.$store.state.searchInfo = result// 赋值给表单中的的字段
        }
      }
    },
    // 退出登录
    loginOut() {
      localStorage.removeItem("japan_user_token");
      localStorage.removeItem("keyWord");
      localStorage.removeItem('gkValue');
      this.$store.commit("userData", null);
      this.$store.commit("setcartCount", 0);
      this.$store.commit('setPage', '');
      this.$store.commit("setOrdersSumList", []);
      this.$store.commit("setChinaLogisticsListTableNumList", []);
      this.$store.commit("setStorageDeliverNumList", []);
      this.$store.commit("setWarehouseSumList", []);
      this.$store.commit("getsearchInfo", '');
      this.$store.commit("setCollectionSumList", []);
      this.$fun.routerToPage("/");
    },
    submitImgUpload() {
      if (this.$fun.isOfficialWeb()) {
        __bl.sum('使用复制图片到搜索框的使用数');
      }
      this.pageLoading = this.$loading({
        lock: true,
        text: this.$fanyi("图片上传中"),
        spinner: "el-icon-loading fontSize50",
        background: "rgba(255, 255, 255, .7)",
      });
      this.files[0].uid = this.files[0].lastModified;
      let obj = {
        file: this.files[0]
      }
      this.httpup(obj)
    },
    //第一个个人中心鼠标移出
    personalCenterOneMouseleave() {
      this.personalCenterOne = false;
    },
    //购物车鼠标移入
    cartMouseenter() {
      this.cart = true
    },
    //购物车鼠标移入
    chatMouseenter() {
      this.chat = true;
      if (this.messageUnreadNum > 0) {
        clearInterval(this.goodsMessageUnread);
      }
    },
    calendarMouseenter() {
      this.calendarStatus = true;
    },
    calendarMouseleave() {
      this.calendarStatus = false;
    },
    orderMouseenter() {
      this.orderStatus = true;
    },
    orderMouseleave() {
      this.orderStatus = false;
    },
    issueOrderMouseenter() {
      this.issueOrderStatus = true;
    },
    issueOrderMouseleave() {
      this.issueOrderStatus = false;
    },
    warehouseMouseenter() {
      this.warehouseStatus = true;
    },
    warehouseMouseleave() {
      this.warehouseStatus = false;
    },
    porderMouseenter() {
      this.porderStatus = true;
    },
    porderMouseleave() {
      this.porderStatus = false;
    },
    //购物车鼠标移出
    cartMouseleave() {
      this.cart = false
    },
    chatMouseleave() {
      this.chat = false;
      if (this.messageUnreadNum > 0) {
        this.goodsMessageUnread = setInterval(() => {
          this.newIsShow = !this.newIsShow;
        }, 900)
      }
    },
    befoUpload(file) {
      const isJPG = ["image/jpeg", "image/png", "image/jpg"].indexOf(file.type) != -1;
      if (!isJPG) {
        this.$message.error(this.$fanyi("请上传图片"));
        return isJPG;
      }
      this.pageLoading = this.$loading({
        lock: true,
        text: this.$fanyi("图片上传中"),
        spinner: "el-icon-loading fontSize50",
        background: "rgba(255, 255, 255, .7)",
      });
    },
    toTop() {
      let timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 60);
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 2);
    },
    // 图片上传成功事件
    successup(res) {
      if (this.$fun.isOfficialWeb()) {
        __bl.sum('图搜数量统计');
      }
      this.$api.getBase64({
        picUrl: res
      }).then((newResult) => {
        let index = newResult.data.indexOf('base64,');
        let keyWord = newResult.data.substr(index + 7, newResult.data.length);
        this.$api.uploadImagePic({
          imageBase64: keyWord
        }).then((result) => {
          this.pageLoading.close();
          this.$store.commit("getsearchInfo", '');
          if (result.data.result == undefined || result.data.result.result == undefined || result.code != 0) return;
          this.$fun.routerToPage("/CommoditySearch?type=imgSearch&imgUrl=" + res + "&imageId=" + result.data.result.result);
        });
      })
    },
    //点击页面左侧菜单页面跳转
    goPage(val) {
      if (localStorage.getItem('japan_user_token') == null) {
        this.$refs.dialog.visible = true;
        this.$store.commit('setPage', val)
        this.$forceUpdate();
      } else {
        if (val == 'cart' || val == 'warehouse') {
          window.open(`/${val}`)
        } else if (val == 'chat') {
          this.chatPageJump()
        } else {
          window.open(`/user/${val}`)
        }
      }
    },
    //页面刷新时清空
    handleBeforeUnload() {
      this.$store.commit("getsearchInfo", '');
    },
    async chatPageJump() {
      if (location.host.indexOf('.co.jp') !== -1 || location.host.indexOf('.com') !== -1) {
        // if (this.$store.state.userInfo == null || (this.$store.state.userInfo != null && this.$store.state.userInfo.chatwork == '')) {
        let user_token = localStorage.getItem('japan_user_token')
        this.loading = this.$loading({
          lock: true,
          spinner: "el-icon-loading fontSize50",
          background: "rgba(255, 255, 255, 0.7)",
        });
        await this.$api.userTimGroupCheck({user_id: this.$store.state.userInfo.operation_id}).then((res) => {
          this.loading.close();
          var a = document.createElement("a");
          a.setAttribute("href", `https://rakuchat.rakumart.com/client_web/#/?id=${this.$store.state.userInfo.operation_id}&token=${user_token}`);
          a.setAttribute("target", '_blank')
          let clickEvent = document.createEvent("MouseEvents");
          clickEvent.initEvent("click", true, true);
          a.dispatchEvent(clickEvent);
        });
        // } else {
        //   var a = document.createElement("a");
        //   a.setAttribute("href", `https://www.chatwork.com/#!rid' + ${this.$store.state.userInfo.chatwork}`);
        //   a.setAttribute("target", '_blank')
        //   let clickEvent = document.createEvent("MouseEvents");
        //   clickEvent.initEvent("click", true, true);
        //   a.dispatchEvent(clickEvent);
        // }
      }
    },
    //点击购物车进行跳转
    cartPageJump() {
      if (this.$store.state.userInfo == null && this.token == false) {
        this.$refs.dialog.visible = true;
      } else {
        this.$fun.routerToPage('/cart');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./css/HomePageTop.scss";

.upImg /deep/ .el-upload--picture-card {
  background: transparent;
  height: 54px !important;
  line-height: normal;
}
</style>
